import React from "react"
import PropTypes from "prop-types"

import styles from "./sponsorLogo.module.scss"

const SponsorLogo = ({ logo, name, link, underline }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={link}
    className={`${styles.sponsorLogo} ${underline ? styles.underline : ""}`}
  >
    <img src={logo} alt={name} />
  </a>
)

SponsorLogo.propTypes = {
  logo: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  underline: PropTypes.bool,
}

export default SponsorLogo
