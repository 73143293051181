import React from 'react';

import styles from './sponsors.module.scss';

import SponsorLogo from '../atoms/sponsorLogo';

import Bluegg from '../../images/bluegg.svg';
import UHY from '../../images/uhy.svg';
import Ashcroft from '../../images/ashcroft.svg';

import Rockadove from '../../images/rockadove.svg';
import Gavel from '../../images/gavel.svg';
import Grahame from '../../images/graham.svg';
import McAlister from '../../images/mcalister.svg';
import Alex from '../../images/alex-jenkins.svg';
import Bowen from '../../images/bowen-eldridge.svg';
import Loosemore from '../../images/loosemore.svg';

const Sponsors = () => (
	<div className={styles.sponsors}>
		<div className="wrapper">
			<div className="grid grid--wide-gap">
				<div className="grid-child grid--push-start-2 grid-child--4-col-l grid-child--6-col-s">
					<div className={styles.sponsorsTitle}>
						<h3 className="heading h2">Our Partners</h3>
					</div>
				</div>
				<div className="grid-child grid--push-start-6 grid-child--6-col-l grid-child--6-col-s">
					<div className={styles.sponsorsGrid}>
						<SponsorLogo name="Bluegg" logo={Bluegg} link={'https://bluegg.co.uk'} />
						<SponsorLogo name="UHY" logo={UHY} link={'https://www.uhy-uk.com/newport/'} />
						<SponsorLogo
							name="Ashcroft Wealth"
							logo={Ashcroft}
							link={'http://jonashcroft.2plan.com/'}
							underline={true}
						/>
					</div>
				</div>
				<div className="grid-child grid--push-start-2 grid-child--4-col-l grid-child--6-col-s">
					<div className={styles.sponsorsTitle}>
						<h3 className="heading h2">Our Sponsors</h3>
					</div>
				</div>
				<div className="grid-child grid--push-start-6 grid-child--6-col-l grid-child--6-col-s">
					<div className={styles.sponsorGridTwoCol}>
						<SponsorLogo name="Rockadove" logo={Rockadove} link={'https://www.rockadove.co.uk/'} />
						<SponsorLogo name="McAlister" logo={McAlister} link={'https://www.mcalisterco.co.uk/'} />
						<SponsorLogo name="Gavel" logo={Gavel} link={'http://www.gavelauctioneers.com/'} />
						<SponsorLogo name="Grahame" logo={Grahame} link={'http://www.guilfordco.wales/home'} />
						<SponsorLogo name="Bowen Eldridge" logo={Bowen} link={'https://www.boweneldridge.co.uk/'} />
						<SponsorLogo name="Alex Jenkins" logo={Alex} link={'https://www.alexlloydjenkins.com/'} />
						<SponsorLogo
							name="Loosemore Solicitors"
							logo={Loosemore}
							link={'https://www.loosemores.co.uk/'}
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default Sponsors;
